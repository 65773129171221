import React from 'react';
import './About.css';

function About() {

    return (
        <div className='About'>
            
            <h2 id="about">&frasl;/about//</h2>
            <p>dj choyce hacks! wants to make you <span role="img" style={{fontSize: '1.5em'}} aria-label="dance emjoi">🕺</span><em>dance</em><span role="img" style={{fontSize: '1.5em'}} aria-label={"dance emjoi"}>🕺</span>. From his early days in the 90s american rave scene, to modern day Burning Man Decompression parties, it has always been about making people dance... And smile : ) Consider it a non-stop journey to provide happiness. </p>
            <p>He focuses on both infectious pop-music parties and on his signature, high energy disco-house sound. Featuring 70s funk and horns, driving baselines, modern day production, and euphoric build ups, Choyce makes you leave it all in the dancefloor. </p>
            
            <p>Choyce is Co-creator of Brooklyn’s infamous Robyn Party (aka <a href="http://thispartyiskillingyou.com">This Party Is Killing You</a>) and has been a mainstay in the Brooklyn underground scene for the last 10 years. He's thrown multiple immersive parties to celebrate the movie Hackers, started Brooklyn's first (and only?) MySpace tribute night, and has been a private DJ for uber-chic Louis Vuitton events.</p>

            <p>He’s performed along side internationally known artists such as <a href="https://www.instagram.com/robynkonichiwa/">Robyn</a>, <a href="https://www.instagram.com/thecrystalmethod/">The Crystal Method</a>, <a href="https://www.instagram.com/kiesza/">Kiesza</a>, <a href="https://www.instagram.com/littlebootsmusic/">Little Boots</a>, and <a href="https://www.instagram.com/dieselboy/">Dieselboy</a>, while also holding down the stage with local and regional artists a plenty.</p>

            <p>He makes a home at the iconic <a href="https://www.brooklynbowl.com/">Brooklyn Bowl</a> in Brooklyn, NY and has performed across the USA and abroad at LA’s <a href="http://www.spacelandpresents.com/events/echoplex/">Echoplex</a>, Berlin's <a href="https://www.schwuz.de/">SchwuZ</a>, Portland's <a href="https://www.holocene.org">Holocene</a>, SF's <a href="https://www.augusthallsf.com/">August Hall</a>, Austin's <a href="http://www.ironwoodhall.com/">Ironwood Hall</a>, Atlanta's <a href="https://basementatl.com/">The Basement</a>, Nashville's <a href="https://www.exitin.com/">EXIT/IN</a>, Israel's <a href="https://midburn.org/en/">MidBurn Festival</a>, and many more.
            </p>

            <p></p>
        </div>
    )
}

export default About;


import React from 'react';
import './App.css';
import SocialNav from './components/nav/SocialNav';
import MainNav from './components/nav/MainNav';
import Events from './components/Events';
import About from './components/About';
import Media from './components/Media';
import Footer from './components/Footer';

function App() {
    return (
        <div>
            <div className="App">

                <header className="App-header">
                    <h1 className="pure-menu-heading">dj choyce hacks!</h1>

                    <SocialNav/>

                    <MainNav/>

                </header>
            </div>
            <div className="Body">

                <div className={'spacer'}/>

                <About/>

                <div className={'spacer'}/>

                <div>
                    <h2 id="mixtape">&frasl;/featured mixtape//</h2>
                    <iframe width="100%" height="166" scrolling="no"
                            frameBorder="no" allow="autoplay"
                            title="featured-mix"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/676915482&color=%2300ffd8&auto_play=false&hide_related=true&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>
                </div>

                <div className={'spacer'}/>

                <div>
                    <h2 id="hack-the-dancefloor">&frasl;/hack the dancefloor//</h2>
                    <h5>Check out my rotating list of dance floor jams on spotify!</h5>
                    <h1><a href="https://open.spotify.com/playlist/2PAd48E3jAa2LrUAeBVAN5?si=wOtGmyEPQvSyVMLuWBMt_w"
                           target="_blank"
                           rel="noopener noreferrer">Click here to subscribe</a></h1>
                    <iframe
                        src="https://open.spotify.com/embed/playlist/2PAd48E3jAa2LrUAeBVAN5"
                        width="90%" height="300"
                        frameBorder="0"
                        allowtransparency="true"
                        title="spotify-embed"
                        allow="encrypted-media"/>
                </div>

                <div className={'spacer'}/>

                <Media/>

                <div className={'spacer'}/>
                
                <div>
                    <h2 id="events">&frasl;/events//</h2>

                    <Events/>
                </div>

                <div className={'spacer'}/>

            </div>

            <Footer/>
        </div>
    );
}

export default App;

import React from 'react';
import './Event.css';

function Events() {

    const getUpcomingEvents = () => {
        return [
            ['Dec 13 2019', 'Brooklyn Bowl - This Party Is Killing You', 'https://www.facebook.com/events/403234133914824/'],            
        ]
    };

    const getPastEvents = () => {
        return [
            ['Dec 06 2019', 'Escondido - BLIND NYC Launch Party', ''],
            ['Dec 05 2019', 'Her Name Was Carmen', ''],
            ['Oct 18 2019', 'Brooklyn Bowl - This Party Is Killing You', 'https://www.facebook.com/events/508351776658420/'],
            ['Sep 28 2019', 'NYC Official Decom, New York, NY', '', 'https://soundcloud.com/hacks/live-at-2019-official-burning-man-nyc-decompression'],
            ['Sep 05 2019', 'Brooklyn Bowl w/ DETOX', '', '', 'https://www.facebook.com/events/2318498738463574/'],
            ['Aug 2019', 'Burning Man 2019, Black Rock City, Nevada'],
            ['Jul 19 2019', 'Brooklyn Bowl', '', '', 'https://www.facebook.com/events/316396095904719'],
            ['Jul 27 2019', 'Ironwood Hall, Austin, TX', '', '', 'https://www.facebook.com/events/1890712861029136/'],
            ['Jun 28 2019', 'Robyn PRIDE Party @ Brooklyn Bowl', 'event-featured'],
            ['Jun 08 2019', 'Private Event, Kauai, Hawaii'],
            ['Jun 01 2019', 'Echoplex, LA'],
            ['May 31 2019', 'August Hall, SF'],
            ['May 30 2019', 'Holocene, Portland, OR'],
            ['May 17 2019', 'Private Event, NYC'],
            ['May 08 2019', 'PS1, NYC - Louis Vuitton Cruse 2020 Official Afterparty w/ Mark Ronson', 'event-featured'],
            ['May 04 2019', 'Brooklyn Bowl, NYC'],
            ['Mar 06 2019', 'Brooklyn Bowl, NYC'],
            ['Feb 23 2019', 'The Basement, Atlanta, GA'],
            ['Feb 21 2019', 'EXIT/IN, Nashvill, TN'],
            ['Feb 16 2019', 'Echoplex, LA'],
            ['Feb 15 2019', 'Holocene, Portland'],
            ['Feb 14 2019', 'Echoplex, LA w/ CLASSIXX'],
            ['Feb 01 2019', 'Brooklyn Bowl, NYC'],
            ['Dec 15 2018', 'Brooklyn Bowl, NYC'],
            ['Dec 01 2018', 'Dr. Pong, Berlin, Germany', 'event-featured', 'https://soundcloud.com/hacks/live-dr-pong-berlin-germany-2018'],
            ['Nov 30th 2018', 'SchwuZ, Berlin, Germany', 'event-featured'],
            ['Nov 9 2018', 'Ondalinda // Samadhi Camp, Careyes, MX', 'event-featured', 'https://soundcloud.com/hacks/live-samadhi-camp-ondalinda-2018'],
            ['Nov 2 2018', 'Echoplex, LA'],
            ['Nov 1 2018', 'Palm Springs, CA'],
            ['Oct 31 2018', 'August Hall, SF'],
            ['Oct 27 2018', 'Elsewhere, NYC'],
            ['Oct 25 2018', 'Brooklyn Bowl, NYC'],
            ['Sep 29 2018', 'NYC Official Decom, New York, NY', '', 'https://soundcloud.com/hacks/live-ny-decom-2018-09-29'],
            ['Sep 15 2018', 'Brooklyn Bowl'],
            ['Aug 2018', 'Burning Man 2018, Black Rock City, Nevada'],
            ['Aug 17 2018', 'Brooklyn Bowl'],
            ['June 30 2018', 'StageLIC/Moma PS1, NYC'],
            ['May 21 2018', 'Brooklyn Bowl w/ Guest Appearance by ROBYN', 'event-featured'],
            ['May 16 2018', 'Midburn, Negev Desert, Israel', 'event-featured', 'https://soundcloud.com/hacks/midburn-2018'],
            ['May 5 2018', 'Private event, NYC'],
            ['Apr 14 2018', 'Friends & Lovers, NYC'],
            ['Apr 11 2018', 'Private event, NYC'],
            ['Feb 27 2018', 'ET AL, NYC'],
            ['Dec 09 2017', 'Brooklyn Bowl, NYC'],
            ['Oct 21 2017', 'Cape House, NYC', '', 'https://soundcloud.com/hacks/avi-burn'],
            ['Oct 05 2017', 'Terminal 5, NYC (Mr. Robot Launch Party)', 'event-featured'],
            ['Aug 30 2017', 'Burning Man 2017, Black Rock City, Nevada'],
            ['Jul 08 2017', 'Brooklyn Bowl'],
            ['May 05 2017', 'Brooklyn Bow'],
            ['Oct 09 2016', 'Terminal 5, NYC w/ The Crystal Method', 'event-featured'],
    ]
    };

    const renderUpcomingEvents = () => {
        return getUpcomingEvents().map(function(event){
            return <div className="event" key={event[0]}>{event[0]} @ {event[1]}<br/><a href={event[2]}>{event[2]}</a></div>
        });
    };

    const renderPastEvents = () => {
        return getPastEvents().map(function(event){
            if (event.length === 2) {
                return <div className="event event-past" key={event[0]}>{event[0]} @ {event[1]}</div>
            } else if (event.length >= 3) {

                const className = "event-past " + event[2];
                let link = null;

                if(event.length === 4) {
                    // include a URL
                    link = <div><a href={event[3]} target="_blank" rel="noopener noreferrer">{event[3]}</a></div>
                }

                return (
                    <div className={'event'} key={event[0]}>
                        <div className={className}>{event[0]} @ {event[1]}</div>
                        {link}
                    </div>
                )
            }
            return (<div></div>)
        });
    };


    return (
        <div>
            {renderUpcomingEvents()}
            <h4>Past events:</h4>
            {renderPastEvents()}
        </div>
    )
}

export default Events;
import React from 'react';
import './SocialNav.css';

function SocialNav() {
    return (
        <div className="home-menu pure-menu pure-menu-horizontal">
            <ul className="pure-menu-list">
                <li className="pure-menu-item">
                    <a href="https://facebook.com/djchoycehacks"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-facebook-f-50.png' alt="dj choyce hacks! on Facebook"
                    /></a>
                </li>
                <li className="pure-menu-item">
                    <a href="https://instagram.com/djchoycehacks"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-instagram-50.png' alt="dj choyce hacks! on Instagram"
                    /></a>
                </li>
                <li className="pure-menu-item">
                    <a href="https://twitter.com/djchoycehacks"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-twitter-squared-50.png' alt="dj choyce hacks! on Twitter"
                    /></a>
                </li>
                <li className="pure-menu-item">
                    <a href="https://soundcloud.com/hacks"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-soundcloud-50.png' alt="dj choyce hacks! on Soundcloud"
                    /></a>
                </li>
                <li className="pure-menu-item">
                    <a href="https://open.spotify.com/user/olbf4xtp7h8v0afyv31vec76a?si=e8DdYPF4TzqKr34V47HwhA"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-spotify-50.png' alt="dj choyce hacks! on Spotify"
                    /></a>
                </li>
                <li className="pure-menu-item">
                    <a href="mailto:letsdance@djchoycehacks.com"
                       className="pure-menu-link"
                       target="_blank"  rel="noopener noreferrer"
                        ><img src='/images/icons8-important-mail-48.png' alt="email"
                    /></a>
                </li>
            </ul>
        </div>
    )
}

export default SocialNav;
import React from 'react';
import './MainNav.css';

function MainNav() {
    return (
        <div className="home-menu pure-menu pure-menu-horizontal MainNav-list">
            <ul className="pure-menu-list">
            
                <li className="pure-menu-item">
                    <a href="#about" className="pure-menu-link">about</a>
                </li>
                
                <li className="pure-menu-item">
                    <a href="#mixtape" className="pure-menu-link">mixtape</a>
                </li>

                <li className="pure-menu-item">
                    <a href="#hack-the-dancefloor" className="pure-menu-link">playlist</a>
                </li>

                <li className="pure-menu-item">
                    <a href="#media" className="pure-menu-link">media</a>
                </li>
                
                <li className="pure-menu-item">
                    <a href="#events" className="pure-menu-link">events</a>
                </li>

            </ul>
        </div>
    )
}

export default MainNav;
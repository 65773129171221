import React from 'react';
import InstagramEmbed from 'react-instagram-embed';
import './Media.css';

function Media() {

    return (
        <div className='Media'>
            <h2 id="media">&frasl;/media//</h2>
            <p>(note: media comes from <a href="https://www.instagram.com/djchoycehacks/">Instagram</a>. If you have an ad blocker these may not show up!)</p>

            <div className="Media-content">
                <div className="Media-column">
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B2MqIHslF35/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B1HLLf6HJSf/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B0WT3uXptO_/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/BzZJJxrJkQR/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/BuWUVZFnA-W/'
                        hideCaption={true}
                    />
                </div>
                <div className="Media-column">
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B0iuWprnucj/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/BjFXldyF7d0/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B2fVaK-JAe6/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/ByLL5rVJLO0/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/BxSYYLpJTSA/'
                        hideCaption={true}
                    />
                </div>
                
                <div className="Media-column">    
                    <InstagramEmbed
                        url='https://www.instagram.com/p/B0rLtcOJkAo/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/Bq4idbrAxQv/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/Bn9JKzDgRGr/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/Bkux-NIFIOA/'
                        hideCaption={true}
                    />
                    <InstagramEmbed
                        url='https://www.instagram.com/p/ByIdy3-pGu0/'
                        hideCaption={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default Media;

